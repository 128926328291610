import React, { useState, useEffect, Fragment } from "react";
import { BannerHome } from "../components/BannerHome";
import { PeopleEdutalk } from "../components/PeopleEdutalk";
import { IntroduceJob } from "../components/IntroduceJob";
import { RecruimentProcess } from "../components/RecruimentProcess";
import { ReasonWhy } from "../components/ReasonWhy";
import { MomentEdutalk } from "../components/MomentEdutalk";
import { Quote } from "../components/Quote";

export default function Home() {
  
  return (
    <div>    
      {/* main */}
      <main>
        {/* banner home */}
        <BannerHome></BannerHome>

        {/* THẾ GIỚI VIỆC LÀM EDUTALK */}
        <IntroduceJob />

        {/* QUY TRÌNH TUYỂN DỤNG TẠI EDUTALK */}
        <RecruimentProcess />

        {/* VÌ SAO NÊN CHỌN LÀM VIỆC TẠI EDUTALK  */}
        <ReasonWhy />

        {/* KHOẢNH KHẮC EDUTALK */}
        <MomentEdutalk />

        {/* QUOTE */}
        <Quote />

        {/* CON NGƯỜI EDUTALK */}
        <PeopleEdutalk />
      </main>
    </div>
  );
}
