import React from "react";

export const LoadingCircle = (props: any) => {

  return (
    <>
    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </>
  );
};
