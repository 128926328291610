import React, { Fragment, useEffect } from "react";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from 'swiper';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import photos from "./photos";
import slides from "./slides";
export const MomentEdutalk = () => {
  useEffect(() => {
    setPhotoMoments(photos.slice(0,9))
  }, [photos]);
  const [index, setIndex] = React.useState(-1);
  const [photoMoments, setPhotoMoments] = React.useState(photos);
  const detailImage = (index:any) => {
    setIndex(index);
  }
  const loadMore = () => {
    if(photoMoments.length < photos.length) {
      setPhotoMoments(photos.slice(0,photoMoments.length + 9))
    }
  }

  const SlideCarousel = () => {
    const moments = [
      {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}
    ];
    const listItems = moments.map((moment, index) => (
      <SwiperSlide key={index}>
        <div className="flex justify-center">
          <Image
            id="momentMobile"
            src={`/images/moments/${index == 13 ? (index + 1) +'-1' : (index + 1)}-min.jpg`}
            alt={`moment${index + 1}`}
            className="rounded h-[230px] object-cover"
            width={312}
            height={230}
          />
        </div>
      </SwiperSlide>
    ));
    return (
      <>
        <Fragment>
          <Swiper
            effect="cards"
            modules={[Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={true}
            className="cursor-pointer"
            loop={true}
            speed={400}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {listItems}
          </Swiper>
        </Fragment>
      </>
    );
  };
  return (
    <>
      <section className="mx-auto xl:w-[1100px] lg:w-[800px] md:w-[600px] pb-10 font-gotham-book mb:mt-12 mt-5">
        <h2 className="font-gotham-book text-base mb:text-2xl text-blue-violet flex justify-center ">
          KHOẢNH KHẮC EDUTALK
        </h2>
        <div className="mt-14 mb:block hidden">
        <PhotoAlbum
        layout="rows"
        photos={photoMoments}
        targetRowHeight={300}
        onClick={({ index }) => detailImage(index)}
      />

      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
      />
        </div>
        <div
          onClick={loadMore}
          className="text-blue-violet text-xl mt-10 mb-5 italic underline cursor-pointer mx-auto w-max mb:block hidden"
        >
          {photoMoments.length < photos.length ? "Xem thêm" : ""}
        </div>
        <div
          id="momentMobile"
          className="swipermoment mt-10 justify-center mb:hidden block"
        >
          <SlideCarousel />
        </div>
      </section>
    </>
  );
};
