import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../styles/Banner.module.scss";
import Image from "next/image";
import axios from "axios";
import HeaderMobile from "../HeaderMobile";
import router from "next/router";
import _debounce from 'lodash/debounce';
import { LoadingCircle } from "../LoadingCircle";

const SearchList = ({items,loadingSearch,openSearch}:any) => {

    const [lengthSearch, setLengthSearch] = useState(45);
    useEffect(() => {
        const desktop = window.matchMedia("(min-width: 1440.5px)");
        if (!desktop.matches) {
            setLengthSearch(35);
        }
    }, [items,loadingSearch]);


    const toDetailPage = (id:any) => {
        router.push("/job/" + id)
    }

    const ListItems = items.map((item: any, index: number) => (
        <li onClick={()=>{toDetailPage(item.id)}} key={index} className="hover:bg-gray h-[44px] p-4 flex items-center cursor-pointer uppercase whitespace-nowrap">
            {(item.name.length > lengthSearch ? item.name.slice(0, lengthSearch) + "...." : item.name)}</li>
    ));

    const NotFound = (
        <div className="mt-10 flex flex-col justify-center items-center">
            <Image
                src="/images/not_found.svg"
                alt="icon-search"
                className=""
                width={150}
                height={150}
            />
            <span className="mb-1">Không tìm được kết quả phù hợp</span>
            <span className="text-gray-400 text-lg">Vui lòng tìm kiếm bằng từ khóa khác</span>
        </div>
    );

    const Loading = (
        <div className="mt-10 flex justify-center items-center">
            <LoadingCircle/>
        </div>
    );
    return (
        openSearch && <ul
            className="pt-2 pb-5 text-xl font-gotham-book list-none absolute w-webkit overflow-auto
         bg-white rounded-b-2xl shadow-md overflow-y-scroll z-[2] h-[300px]">
            {loadingSearch ? Loading : (items.length > 0 ? ListItems : NotFound) }
        </ul>
    );
};

export const BannerHome = () => {
        const [nameInput, setNameInput] = useState("");
        const [items, setItems] = useState([]);
        const [loadingSearch, setLoadingSearch] = useState(false);
        const [openSearch, setOpenSearch] = useState(false);
        const [openRadius, setOpenRadius] = useState(false);
        const wrapperRef = useRef(null);
        function useOutsideAlerter(ref: any) {
            useEffect(() => {
                /**
                 * Alert if clicked on outside of NavbarMobile
                 */
                function handleClickOutside(event: any) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setOpenSearch(false);
                        setOpenRadius(false);
                    }
                }
                // Bind the event listener
                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    // Unbind the event listener on clean up
                    document.removeEventListener("mousedown", handleClickOutside);
                };
            }, [ref]);
        }
        useOutsideAlerter(wrapperRef);
        const handleChangeNameInput = (event:any) => {
            if(event == '') {
                setOpenRadius(false);
            } else {
                setOpenRadius(true);
            }
            setNameInput(event);
            debounceDropDown(event)
        }

        const focusSearch = () => {
            setOpenSearch(true);
            if(nameInput) {
                setOpenRadius(true);
            }
        }

        useEffect(() => {

        }, []);

        const debounceDropDown = useCallback(_debounce((nextValue: any) => fetchRecruitment(nextValue), 100), [])

        const fetchRecruitment = (name:any) => {
            setOpenSearch(true);
            setLoadingSearch(true);
            setItems([]);
            axios.get(`${process.env.NEXT_PUBLIC_API_URL}/recruitment/all-office-jobs?name=${name.trim()}`).then(res => {
                setItems(res.data.data);
                setLoadingSearch(false);
            });
        }
        const video_banner_url = "https://edutalk-cdn.sgp1.cdn.digitaloceanspaces.com/video-banner-recruitment/banner-video-recruitment.mp4";

        return(
            <>
                <section className="hidden mb:block relative md:h-[400px] lg:h-[500px] xl:bg-contain xl:h-[600px] 2xl:bg-cover 2xl:h-[764px]">
                    {/* Video Background */}
                    <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", overflow: "hidden" }}>
                        <video autoPlay muted loop id="video-bg" style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                            <source src={video_banner_url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                            
                    {/* Centered Content */}
                    <div className="relative flex flex-col items-center h-full z-10" style={{ paddingTop: "23.5%"}}>
                        <div ref={wrapperRef} onFocus={() => { focusSearch() }} className="relative mt-3 max-w-max">
                            <input
                                name="name"
                                value={nameInput}
                                onChange={e => handleChangeNameInput(e.target.value)}
                                className={`search-container p-5 text-xl font-gotham-book border-none outline-none ${(!openRadius) ? 'rounded-[24px]' : 'rounded-t-[20px] rounded-r-[20px]'}`}
                                placeholder="Bạn muốn tìm việc gì?"
                            />
                            {openRadius ? <div className="border-b border-gray-300 border-solid mr-5"></div> : ''}
                            <div
                                className={[
                                    "bg-[#AB90EA] w-[72.66px] h-full flex justify-center items-center",
                                    styles.banner__search,
                                ].join(" ")}
                            >
                                <Image
                                    src="/images/search.png"
                                    alt="icon-search"
                                    width={37.54}
                                    height={37.2}
                                />
                            </div>
                            {nameInput ? <SearchList items={items} loadingSearch={loadingSearch} openSearch={openSearch} /> : ''}
                        </div>
                    </div>
                </section>
                <div className="mb:hidden block">
                    <HeaderMobile/>
                </div>
            </>
        );
    }
;

