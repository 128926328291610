import React, { Fragment, useState } from "react";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation,Autoplay } from "swiper";

const SlideCarousel = ({ slides, moreQuote, qouteLoad }: any) => {
  const listItems = slides.map((slide: any, index: number) => (
    <SwiperSlide key={index}>
      <div
        className={[
          "each-slide bg-blue-300 flex flex-col items-center pt-16 pb-10",
          moreQuote ? " h-[1000px]" : "",
        ].join(" ")}
      >
        <Image
          src={`/images/people/${slide.img}`}
          alt={`${slide.img}`}
          className="h-fit mb-5"
          width={158}
          height={158}
        />
        <span className="text-blue-violet xl:text-lg lg:text-base md:text-sm">
          {slide.name}
        </span>
        <span className="text-blue-violet xl:text-base lg:text-sm md:text-xs mb-4">
          ({slide.position})
        </span>
        <div className="text-black xl:text-sm lg:text-xs md:text-xs xl:w-[250px] lg:w-[200px] md:w-[150px] text-justify">
          {slide.moreQuote
            ? slide.content
            : slide.content.slice(0, 380) + "....."}
        </div>
        <span
          onClick={() => {
            qouteLoad(index);
          }}
          className="text-blue-violet text-sm mt-5 mb-5 italic underline"
        >
          {slide.moreQuote ? "Thu gọn" : "Xem thêm"}
        </span>
      </div>
    </SwiperSlide>
  ));
  return (
    <Swiper
      spaceBetween={13}
      // centeredSlides={true}
      slidesPerView={3}
      modules={[Navigation,Autoplay]}
      className="cursor-pointer"
      navigation={true}
      loop={true}
      speed={400}
      roundLengths={true}
      loopAdditionalSlides={30}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      {listItems}
    </Swiper>
  );
};

const SlideCarouselmobile = ({ slides, moreQuote, qouteLoad }: any) => {
  const listItems = slides.map((slide: any, index: number) => (
    <SwiperSlide key={index}>
      <div
        className={[
          " bg-blue-300 flex flex-col items-center rounded-xl w-[232px] mx-auto py-2 px-3",
          slide.moreQuote ? " h-[600px]" : "",
        ].join(" ")}
      >
        <Image
          src={`/images/people/${slide.img}`}
          alt={`${slide.img}`}
          className="h-fit mb-5"
          width={158}
          height={158}
        />
        <span className="text-blue-violet text-[10px]">{slide.name}</span>
        <span className="text-blue-violet text-[10px] mb-2">
          ({slide.position})
        </span>
        <div className="text-black text-[10px] w-[174px] text-justify">
          {slide.moreQuote
            ? slide.content
            : slide.content.slice(0, 380) + "....."}
        </div>
        <span
          onClick={() => {
            qouteLoad(index);
          }}
          className="text-blue-violet text-xs mt-2 mb-2 italic underline"
        >
          {slide.moreQuote ? "Thu gọn" : "Xem thêm"}
        </span>
      </div>
    </SwiperSlide>
  ));
  return (
    <>
      <Fragment>
        <Swiper
          spaceBetween={13}
          centeredSlides={true}
          slidesPerView={1}
          modules={[Navigation,Autoplay]}
          className="cursor-pointer"
          navigation={true}
          loop={true}
          speed={400}
          roundLengths={true}
          loopAdditionalSlides={30}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {listItems}
        </Swiper>
      </Fragment>
    </>
  );
};

export const PeopleEdutalk = () => {
  const [moreQuote, setMoreQuote] = useState(false);
  const [slides, setSlides] = useState([
    {
      moreQuote: false,
      img: "hung.svg",
      name: "Anh Hưng",
      position: "RSM kiêm Giám đốc khu vực HN1",
      content:
        "“Từ khi còn là 1 sinh viên với mong muốn phát triển tiếng anh mình cũng tìm rất nhiều trung tâm để học và cũng hiểu được nỗi lòng của các bạn sinh viên khi đi tìm các trung tâm tiếng anh. Và đến khi mình biết đến Edutalk là 1 giải pháp toàn diện hỗ trợ các bạn sinh viên, với sứ mệnh thay đổi và phát triển nền giáo dục Việt Nam, mình đã quyết định sẽ đồng hành cùng Edutalk. Khoảng thời gian gắn bó hơn 6 năm cùng công ty mình có thể nói rằng không 1 môi trường nào có thể cho các bạn trẻ đam mê kinh doanh có thể phát triển nhanh bằng Edutalk. Ở đây không chỉ giúp các bạn phát triển năng lực, kiếm thu nhập mà quan trọng nhất ở đây có những con người tuyệt vời luôn sẵn sàng hỗ trợ bạn.”",
    },
    {
      moreQuote: false,
      img: "ngoan_tran.svg",
      name: "Chị Ngoan Trần",
      position: "Ban đổi mới sản phẩm",
      content:
        '“Khi đang đứng giữa ngã tư với muôn vàn lựa chọn, dường như có một lực hấp dẫn nào đó luôn kéo mình hướng về vầng hào quang của Edutalk. Ở Edutalk, mình thấy mình được tự do thể hiện bản thân, được trở thành hình mẫu mà mình muốn. Ở Edutalk, mình gặp được những "Người Thầy" đích thực. Có một điều mình chắc chắn rằng, bất kỳ ai vào Edutalk cũng trở nên giỏi hơn, bản lĩnh hơn. Bởi vì bạn đang làm việc với những người thành công nhất và bạn sẽ là người thành công tiếp theo. Những lúc buồn, lúc áp lực thì luôn có cấp trên, cấp bên, cấp dưới đồng hàng cùng. Họ không để mình phải đối mặt với những cảm xúc tiêu cực đó một mình. Mình trân trọng quãng thời gian làm việc tại Edutalk!”',
    },
    {
      moreQuote: false,
      img: "vuong.svg",
      name: "Anh Nguyễn Văn Vượng",
      position: "Teamlead phòng Đào tạo",
      content:
        "“Đến với Edutalk như bước chân lên một hạm đội hùng mạnh và vĩ đại, nơi có những con người cùng chung sứ mệnh phụng sự nền giáo dục, đưa trí tuệ Việt Nam ra toàn thế giới. Tôi tự hào là một phần của Edutalk, tôi hạnh phúc được kề vai sát cánh với những người anh em nhiệt thành và can đảm. Chúng tôi đã, đang và sẽ luôn không ngừng nỗ lực hoàn thiện bản thân, xây dựng tổ chức lớn mạnh hơn nữa để mang đến những sản phẩm giáo dục giá trị cho khách hàng và kiến tạo nên môi trường làm việc, rèn luyện lý tưởng cho những thuyền viên của Edutalk”",
    },
    {
      moreQuote: false,
      img: "yen.svg",
      name: "Chị Ngọc Yến",
      position: "RSM kiêm Giám đốc khu vực HN2",
      content:
        "“Trăm sự cố gắng không bằng một sự lựa chọn, và lựa chọn Edutalk là lựa chọn tuyệt vời nhất trong cuộc đời tôi. Nơi đây tôi đã tìm ra được sứ mệnh của chính mình, với khách hàng và đồng đội. Cảm ơn những khó khăn thách thức để tôi biết mình mạnh mẽ và bản lĩnh như nào để chinh phục chặng đường phía trước. Cảm ơn Edutalk vì tôi có thể tạo ra nhiều giá trị hơn nữa trong cuộc sống này.”",
    },
    {
      moreQuote: false,
      img: "huy.svg",
      name: "Anh Vũ Xuân Huy",
      position: "Phòng Công nghệ thông tin",
      content:
        "“Với tôi, một sự lựa chọn đúng còn hơn vạn lần nỗ lực. Tôi chọn Edutalk - Vì ở đây văn hóa tập thể được ưu tiên hàng đầu, nên chẳng ai phải quan tâm tới người được hay kẻ thiệt. Tôi chọn Edutalk - Vì ở đây thất bại được chấp nhận, chỉ có hối hận và từ bỏ mới đáng sợ. Tôi chọn Edutalk - Vì ở đây có những người anh em cùng tôi ngày đêm tiến lên vì Đại hải trình Edutalk, với sứ mệnh khiến thế‌ giới phải nhắc tới và tự hào về‌ con người và trí tuệ Việt Nam”.",
    },
    {
      moreQuote: false,
      img: "le_hoa.svg",
      name: "Chị Lê Hoa",
      position: "Chuyên viên Chăm sóc khách hàng",
      content:
        'Cuộc sống là chuỗi những sự lựa chọn, và tôi may mắn khi đã chọn lựa Edutalk. Tôi may mắn vì được trải qua nhiều vị trí, và hơn hết tôi may mắn vì có môi trường làm việc và cấp trên thật tuyệt vời. Tại Edutalk tôi được học hỏi nhiều điều mới, được mọi người đồng hành và giúp đỡ để trưởng thành hơn rất nhiều. Còn gì tuyệt vời hơn khi đi làm có người cấp trên hiểu tôi, biết tôi tốt điều gì và cần cải thiện ở đâu. Tôi có những người đồng nghiệp hằng ngày luôn cùng tôi hỗ trợ đến từng khách hàng, cùng nhau chia sẻ những lúc vui buồn để mỗi ngày đi làm là một ngày vui. Đối với nhiều người, khi tham gia vào bất kì Công ty nào họ quan tâm đầu tiên là thu nhập nhưng với tôi, tôi lựa chọn là "Những người anh em", và tại Edutalk tôi thực sự hài lòng vì điều đó.',
    },
    {
      moreQuote: false,
      img: "PD.png",
      name: "Chị Phương Dung",
      position: "Teamlead BP Điều phối lớp học",
      content:
        "Lựa chọn làm việc tại một công ty hoạt động trong lĩnh vực giáo dục đồng nghĩa với việc bản thân mình sẽ có cơ hội tạo ra tầm ảnh hưởng, giá trị tích cực đối với cộng đồng và xã hội thông qua những công việc mà hằng ngày mình đang tiếp nhận xử lý. Trải qua 3 năm làm việc, mình học được nhiều kỹ năng mới thông qua các khóa đào tạo và nắm bắt được nhiều kinh nghiệm quý giá. Điều này mang lại sự tự tin và cảm giác tiến bộ trong sự nghiệp của mình. Quãng thời gian làm việc tại công ty, mình phát triển sự hiểu biết sâu hơn về sứ mệnh và tầm nhìn của Edutalk. Mình cảm thấy đồng lòng và đồng hành với các giá trị của công ty, tự hào vì đóng góp mình vào sự thành công của Edutalk. Trong quá trình làm việc 3 năm mình đã từng đối mặt với nhiều thách thức và cơ hội học hỏi mới. Các dự án phức tạp, khóa học đào tạo và cơ hội làm việc với các đồng nghiệp đã mang lại sự phát triển và trưởng thành nhất định đối với bản thân mình. Cảm ơn Edutalk và mình tự hào là người mang ADN của Edutalk.",
    },
    {
      moreQuote: false,
      img: "thuy_trang.png",
      name: "Chị Thùy Trang",
      position: "Trợ lý Giám đốc Chiến lược",
      content:
        'Mình biết đến Edutalk khi đang trong giai đoạn chênh vênh của tuổi 22, chuẩn bị ra trường và mông lung về định hướng. Đối với mình, quyết định đúng đắn nhất tại thời điểm đó là quyết định ứng tuyển và may mắn có cơ hội đồng hành cùng Edutalk. Ở Edutalk, mình được trải nghiệm rất nhiều điều, không chỉ được trao cơ hội quản trị các dự án quan trọng mà còn được thử thách bản thân ở vị trí leader, đó có lẽ vừa là thành tựu lại vừa là thách thức lớn đối với mình. Tuy nhiên bên cạnh mình luôn là sự đồng hành của cấp trên - những người vô cùng xuất chúng, cấp bên luôn chia sẻ, giúp đỡ, lắng nghe và cấp dưới không bao giờ mất niềm tin vào mình. Chính vì vậy, Edutalk không chỉ trao cho mình rất nhiều giá trị mà còn là nơi giúp mình khám phá bản thân để trở thành phiên bản tự tin hơn, năng động hơn và bản lĩnh hơn.',
    },
    {
      moreQuote: false,
      img: "thuy_trang.svg",
      name: "Chị Thúy Trang (Amily)",
      position: "Phòng Sản phẩm ngoại ngữ",
      content:
        '“Nếu ai đó hỏi tôi "Tại sao bạn lựa chọn Edutalk" thì có lẽ tôi sẽ im lặng rất lâu mới có thể trả lời được. Im lặng bởi vì tôi đang tìm kiếm trong từ điển Tiếng Việt để lựa chọn từ ngữ nào có thể diễn tả được suy nghĩ của tôi hay không. Nếu các bạn nghĩ đến Edutalk là "Ngôi nhà thứ 2 của tôi" hay là "Sự nghiệp của tôi" hay cảm xúc hơn là "Tình yêu của tôi" thì tôi sẽ có một sự lựa chọn khác cho riêng mình, đó là bởi vì "TÔI là một phần của CHÚNG TA". Đối với tôi, Edutalk được ví như ngọn lửa đốt cháy tuổi trẻ trong tôi từ rực cháy sang rực cháy nhiều hơn nữa. Nếu bạn muốn phát triển bản thân - hãy đến với Edutalk, Nếu bạn muốn kỷ luật bản thân - hãy đến với Edutalk, Nếu bạn muốn rèn luyện một tinh thần thép - hãy đến với Edutalk. Edutalk - nơi chào đón những con người không ngại khó."',
    },
  ]);

  const qouteLoad = (index?: any) => {
    // đây n
    let slidesFake = [...slides];
    slidesFake[index].moreQuote = !slidesFake[index].moreQuote;
    setSlides(slidesFake);
    setMoreQuote(!moreQuote);
  };

  const action = moreQuote ? "Thu gọn" : "Xem thêm";



  return (
    <>
      <section className="peopleEdutalk mb:mt-12 mt-5 mb:mb-12 mb:5 relative mx-auto xl:w-[1200px] lg:w-[900px] md:w-[700px]">
        <h2 className="font-gotham-book mb:text-2xl text-base text-blue-violet flex justify-center">
          CON NGƯỜI EDUTALK
        </h2>
        <div
          id="peopleEdutalkDesktop"
          className="swiperPerson mx-auto xl:w-[1100px] lg:w-[800px] md:w-[600px] pb-10 font-gotham-book mb:mt-12 mt-4 mb:block hidden"
        >
          <SlideCarousel
            slides={slides}
            moreQuote={moreQuote}
            qouteLoad={qouteLoad}
          />
        </div>
        <div
          id="peopleEdutalkMobile"
          className="swiperPersonMobile mb:mt-12 mt-4 mb:hidden block"
        >
          <SlideCarouselmobile
          slides={slides}
          moreQuote={moreQuote}
          qouteLoad={qouteLoad}
          />
        </div>
      </section>
    </>
  );
};
