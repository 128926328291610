import React from "react";

export const Quote = () => (
  <>
    <section className="bg-blue-violet mb:h-[134px] h-[104px] mb:mt-12 mt-0">
          <div className="mx-auto xl:w-[1100px] lg:w-[800px] md:w-[600px] h-full flex items-center justify-center">
            <div className="font-gotham-book text-blue-violet mb:text-base text-[12px] flex flex-col mb:w-[900px] w-[348px]">
              <span className="text-spanish-yellow mb:text-lg text-[13px]">
                Trong mỗi bước đi cùng Edutalk, chúng ta nhận được nhiều hơn
                những gì chúng ta tìm kiếm...
              </span>
              <span className="text-spanish-yellow text-end">
                Anh Lưu Anh Đức
              </span>
              <span className="text-white text-end">
                Giám Đốc Vận Hành Edutalk
              </span>
            </div>
          </div>
        </section>
  </>
);
