import React from "react";
import Image from "next/image";

export const RecruimentProcess = () => {
  const process_recruitments = [
    {
      title: "Nộp hồ sơ",
      content:
        "Ứng viên nộp hồ sơ trực tuyến tại website hoặc gửi qua email: tuyendung@edutalkvn.com",
      img: "submit_file",
    },
    {
      title: "Sơ vấn qua điện thoại",
      content:
        "Một cuộc điện thoại ngắn được bộ phận Tuyển dụng liên hệ để tìm hiểu sự phù hợp của ứng viên với tiêu chí tuyển dụng tại vị trí cụ thể",
      img: "interview_phone",
    },
    {
      title: "PV với Trưởng Phòng Ban và Phòng Nhân Sự",
      content:
        "Phỏng vấn trực tiếp tại văn phòng làm việc để xác định kiến thức chuyên môn của ứng viên và các kỹ năng khác liên quan đến vị trí ứng tuyển",
      img: "interview_one",
    },
    {
      title: "Phỏng vấn vòng 2 với BOD (tùy vị trí)",
      content:
        "Phỏng vấn với thành viên Ban Giám Đốc Edutalk nhằm hiểu rõ về kỳ vọng của ứng viên tại vị trí ứng tuyển và sự phù hợp với văn hóa, hệ giá trị tại Edutalk",
      img: "interview_one",
    },
    {
      title: "Thông báo kết quả",
      content:
        "Ứng viên nhận kết quả trong vòng 7 ngày kể từ ngày phỏng vấn. Thư mời nhận việc với thông tin chi tiết về lương thưởng, phúc lợi sẽ được gửi qua email ứng viên",
      img: "noti_result",
    },
    {
      title: "Nhận việc",
      content:
        "Chào mừng bạn đến với Gia đình Edutalk! Phòng Nhân sự sẽ liên hệ và hướng dẫn ứng viên chuẩn bị hồ sơ, các thủ tục nhận việc.",
      img: "accept_job",
    },
  ];
  return (
    <>
      <section className="mx-auto xl:w-[1100px] lg:w-[800px] md:w-[600px] pb-10 font-gotham-book mb:mt-12 mt-5">
        <h2 className="font-gotham-book text-base mb:text-2xl text-blue-violet flex justify-center mb-8 mb:mb-10">
          QUY TRÌNH TUYỂN DỤNG TẠI EDUTALK
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          {process_recruitments.map((item, index) => (
            <div className={
              `border-solid border-gray-200
              ${![2,5].includes(index) && 'xl:border-r'}
              ${[0,1,2].includes(index) && 'xl:border-b'}
              `}
              key={index}>
              <div className={`flex justify-center ${[3,4,5].includes(index) && 'xl:mt-4'} mb-4`}>
                <div className="w-1/5">
                  <Image
                    src={`/images/process_recruitment/${item.img}.svg`}
                    alt="recruitment_process_mobile"
                    className=""
                    width={50}
                    height={50}
                  />
                </div>
                <div className="flex flex-col w-2/3">
                  <span className="font-gotham-regular text-blue-violet md:text-lg text-base mb-1">
                    {item.title}
                  </span>
                  <span className="md:text-sm text-xs">
                    {item.content}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
