import React, { Fragment } from "react";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectCoverflow } from "swiper";

export const ReasonWhy = () => {
  const reasons_why = [
    {
      title : 'Phát triển con người',
      content : `<li>Sứ mệnh quan trọng của Edutalk là “Đưa anh em đến với thành công và hạnh phúc”</li>
      <li>
      Cơ hội tham gia hệ thống 18 chương trình đào tạo/năm về kỹ năng mềm, các kỹ năng áp dụng trong công việc
      </li>
      <li>Thu nhập tăng theo năng lực: review tăng lương và cấp bậc 1 quý/lần</li>
      <li>
      Học miễn phí các khóa học tiếng Anh về Giao tiếp, Toeic, Ielts
      </li>`,
      img :'human_develop'
    },
    {
      title : 'Vinh danh và ghi nhận thành tích',
      content : `<li>Vinh danh trên Bảng Vàng Edutalk, ăn mừng chiến thắng OKR,…</li>
      <li>
      Hệ thống các giải thưởng thi đua quý/năm với tính vinh danh cao và phần thưởng hấp dẫn - xứng đáng
      </li>
      <li>Nhân sự thăng tiến dựa trên cấp bậc năng lực và chức danh, cơ hội thăng tiến công bằng, công khai, minh bạch</li>
      <li>
      Được review và định hướng trong giai đoạn nhân sự phát triển sự nghiệp tại doanh nghiệp
      </li>`,
      img :'honor'
    },
    {
      title : 'Hạnh phúc trong công việc và cuộc sống',
      content : `<li>Văn phòng được thiết kế truyền cảm hứng, đầy đủ tiện nghi với đồ uống như trà, cà phê, trà sữa,... luôn sẵn sàng</li>
      <li>
      Trải nghiệm "24h hạnh phúc tại Edutalk", các sự kiện nội bộ đặc sắc: sinh nhật nhân sự, Dấu ấn Edutalk, sinh nhật công ty, bonding, du lịch,...
      </li>
      <li>Văn hóa doanh nghiệp trẻ, nhân văn và đa dạng</li>
      <li>
      Đồng nghiệp giỏi giang, hỗ trợ hết mình
      </li>`,
      img :'happy'
    },
    {
      title : 'Lãnh đạo đồng hành',
      content : `<li>Các chương trình đào tạo 1:1, các buổi chia sẻ từ Trưởng phòng về chuyên môn, kỹ năng trong công việc</li>
      <li>
      Liên tục cập nhật kiến thức mới thông qua những buổi workshop được Ban Giám đốc trực tiếp đứng lớp chia sẻ
      </li>
      <li>Được hỗ trợ giải quyết các vấn đề khi nhân sự gặp khó khăn trong chuyên môn và quản trị công việc</li>
      <li>
      Được lắng nghe, chia sẻ về những kết quả, bài học và hướng đi sắp tới thông qua các buổi họp, sự kiện
      </li>`,
      img :'leadership'
    },
  ] 
  const reasons_why_mobile = [
    [
      reasons_why[0],reasons_why[1]
    ],
    [
      reasons_why[2],reasons_why[3]
    ],

  ]
  const SlideCarousel = () => {
    const listItems = (
      <>
      {reasons_why_mobile.map((item_page, index) => (
        <SwiperSlide key={index}>
          <div
            className={["bg-blue-violet flex font-gotham-book pt-4 pb-6"].join(
              " "
            )}
          >
            {item_page.map((item, index) => (
            <div key={index} className="w-1/2">
              <div className="flex flex-col ml-8">
                <h3 className="text-spanish-yellow text-xs w-[135px] h-[32px] mb-2">
                  {item.title}
                </h3>
                <Image
                  src={`/images/reason_why/${item.img}.jpg`}
                  alt={`${item.img}`}
                  className="mb-4 rounded-lg border"
                  width={135}
                  height={80}
                />
                <ul dangerouslySetInnerHTML={{ __html: item?.content }} className="text-white text-justify w-[164px] text-[10px] list-disc list-inside max-w-[150px]">
                </ul>
              </div>
            </div>
            ))}
          </div>
        </SwiperSlide>
        ))}
      </>
    );
    return (
      <>
        <Fragment>
          <Swiper
            spaceBetween={13}
            centeredSlides={true}
            slidesPerView={1}
            modules={[Pagination]}
            className=" cursor-pointer"
            pagination={{ clickable: true }}
            loop={true}
            speed={400}
            roundLengths={true}
            loopAdditionalSlides={30}
          >
            {listItems}
          </Swiper>
        </Fragment>
      </>
    );
  };
  return (
    <>
      <section>
        <div className="bg-blue-violet mb:mt-12 mt-01 mb:flex hidden">
          <div className="mx-auto xl:w-[1100px] lg:w-[800px] md:w-[600px] pb-20 font-gotham-book mb:mt-16 mt-5">
            <h2 className="font-gotham-book text-base mb:text-2xl text-spanish-yellow flex justify-center mb-10 mb:mb-16">
              VÌ SAO NÊN CHỌN LÀM VIỆC TẠI EDUTALK
            </h2>
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 gap-8">
            {reasons_why.map((item, index) => (
              <div key={index} className="flex">
                <Image
                    src={`/images/reason_why/${item.img}.jpg`}
                    alt={`${item.img}`}
                    className="mb-4 rounded-lg border"
                    width={220}
                    height={220}
                  />
                <div className="flex flex-col ml-8">
                <span className="font-gotham-regular text-spanish-yellow md:text-base text-base mb-1">
                  {item.title}
                </span>
                <ul dangerouslySetInnerHTML={{ __html: item?.content }} className="text-white text-justify text-xs list-disc list-inside">
                </ul>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>

        <div
          id="reasonWhyMobile"
          className="swiperReason bg-blue-violet mt-10 justify-center mb:hidden flex-col"
        >
          <h2 className="font-gotham-book text-base text-spanish-yellow flex justify-center pt-6">
            VÌ SAO NÊN CHỌN LÀM VIỆC TẠI EDUTALK
          </h2>
          <SlideCarousel />
        </div>
      </section>
    </>
  );
};
